<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>공지사항</h1></div>
    <common-area-search
        :row="2"
        :colspan="2"
        @search="commonOnChangeEvent"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R1_C1>
        <label>공지구분</label>
        <ul>
          <kendo-dropdownlist
              ref="boardGbnRef"
              style="width: 100%"
              :data-source="$store.state.codeRequestStore.search.combobox.boardGbn.data"
              :data-text-field="'cdNm'"
              :data-value-field="'cdId'"
              :option-label="'전체'"
              @change="commonOnChangeEvent">
          </kendo-dropdownlist>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>공지기간</label>
        <ul>
          <common-range-datepicker
              ref="dtRef"
              @change="commonOnChangeEvent"
          >
          </common-range-datepicker>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>조회구분</label>
        <ul>
          <kendo-dropdownlist
              ref="viewAllYnRef"
              style="width: 100%"
              :data-source="[{ text : '모든업체' , value : 'Y',},{ text : '선택업체' , value : 'N',}]"
              :data-text-field="'text'"
              :data-value-field="'value'"
              :option-label="'전체'"
              @change="commonOnChangeEvent">
          </kendo-dropdownlist>
        </ul>
      </template>
      <template v-slot:R2_C2>
        <label>작성일</label>
        <ul>
          <common-range-datepicker
              ref="frstRgstDtRef"
              @change="commonOnChangeEvent"
          >
          </common-range-datepicker>
        </ul>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->

    <section>
      <div class="contents_top_sec">
        <div class="c_t_button_wrap fl">
          <button v-if="loginUserData.auth !== Constant.efs.auth.OPERATION_MANAGER"
                  @click="$router.push({name:'BoardNew'})" class="mid_btn orange mr5 ">공지등록</button>
        </div>
      </div>

      <div class="content_list">
        <!-- Kendo 영역 -->
        <kendo-grid ref="gridRef"
                    :data-source="computedBoardGridRows"
                    :navigatable="false"
                    :sortable-mode="'multiple'"
                    :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                    :reorderable="true"
                    :column-menu="false"
                    :resizable="true"
                    :selectable="'row'"
                    :columns="gridColumns"
                    :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
                    @change="onChangeGrid"
        />
        <!-- Kendo 영역 끝 -->
      </div>
    </section>
  </div>
</template>

<script>
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersEfsUtil from '@/common/etners.efs.util'
import ApiConfig from '@/api/api.config'
import { mapGetters } from 'vuex'
import Constant from '@/common/constant'

export default {
  name: 'BoardList',
  computed: {
    computedBoardGridRows: function () {
      const vm = this

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/board`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let filters = vm.searchFilterParam()
              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.lastRouteParams = {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
                ...filters
              }
              return vm.lastRouteParams
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.gridRef.dataSource.page() - 1)
                * vm.$refs.gridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return false
            }
          },
        },

        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
    ...mapGetters(['loginUserData'])
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name === 'BoardDetail'){
        vm.$refs.gridRef.kendoWidget()?.dataSource.read()
      }
    })
  },
  mounted () {},
  methods: {
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 회사, 조회일자, 공정파트구분, 공정기록구분
       * */
      refs.boardGbnRef?.kendoWidget().value('')
      refs.viewAllYn?.kendoWidget().value('')
      refs.dtRef?.widget().init()
      refs.frstRgstDtRef?.widget().init()
      this.commonOnChangeEvent()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      //공지구분
      const boardGbn = refs.boardGbnRef.kendoWidget().value()
      if (boardGbn) {searchFilterOptions.searchBoardGbn = boardGbn}

      //공지기간
      const dtRefValue = refs.dtRef?.widget()?.range()
      if (dtRefValue) {
        const searchStartDt = dtRefValue.start
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.start) : ''
        const searchEndDt = dtRefValue.end
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.end) : ''
        if(!!searchStartDt || !!searchEndDt){
          searchFilterOptions.searchSrtDt = searchStartDt
          searchFilterOptions.searchEndDt = searchEndDt
        }
      }

      //공지구분
      const viewAllYn = refs.viewAllYnRef.kendoWidget().value()
      if (viewAllYn) {searchFilterOptions.searchViewAllYn = viewAllYn}

      //공지기간
      const frstRgstDtRefValue = refs.frstRgstDtRef?.widget()?.range()
      if (frstRgstDtRefValue) {
        const searchStartDt = frstRgstDtRefValue.start
            ? EtnersDateTimeUtil.dateToYYYYMMDD(frstRgstDtRefValue.start) : ''
        const searchEndDt = frstRgstDtRefValue.end
            ? EtnersDateTimeUtil.dateToYYYYMMDD(frstRgstDtRefValue.end) : ''
        if(!!searchStartDt || !!searchEndDt){
          searchFilterOptions.searchSrtFrstRgstDt = searchStartDt
          searchFilterOptions.searchEndFrstRgstDt = searchEndDt
        }
      }
      return searchFilterOptions ?? undefined
    },
    onChangeGrid (ev) {
      let cellIdx = window.event.target.cellIndex
      let boolForg = window.event.target.className === 'forg' // 키워드 강조 span태그 클릭시에도 deatil로 이동
      if (!isNaN(cellIdx) || boolForg) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.$router.push(
            { name: 'BoardDetail',
              params: { boardId : rowData.boardId }
            })
      }
    },
    commonOnChangeEvent: function () {
      const gridRef = this.$refs.gridRef
      gridRef?.kendoWidget()?.dataSource?.query({
        sort: {},
        take: 10,
        page:  1,
        pageSize:  10,
      })
    },
  },
  data () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    const rowRowAttributes = { class :" #= alwYn === 'Y' ? ' notice-top-row': '' # " }
    const vm = this
    return {
      vm: vm,
      Constant,
      ApiConfig : ApiConfig,
      lastRouteParams : {},
      gridColumns: [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
          sortable: false
        },
        {
          field: 'boardGbnNm',
          title: '공지구분',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
        },
        {
          field: 'viewAllYn',
          title: '조회구분',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
          template: function (dataItem) {
            return dataItem.viewAllYn === 'Y' ? '모든업체' : (dataItem.viewAllYn === 'N' ? '선택업체': '')
          }
        },
        {
          field: 'title',
          title: '제목',
          width: '20%',
          attributes: { ...rowRowAttributes },
          headerAttributes: headerAttributes,
        },
        {
          field: 'fileId',
          title: '첨부파일',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
          template: function (dataItem) {
            return !!dataItem.fileId ? '<span class="k-icon k-i-file-zip icon_file"></span>' : ''
          }
        },
        {
          field: 'srtDt',
          title: '공지기간',
          width: '15%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
          template: function (dataItem) {
            return `${dataItem.srtDt} ~ ${dataItem.endDt}`
          }
        },
        {
          field: 'frstRgstNm',
          title: '작성자',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
        },
        {
          field: 'frstRgstDt',
          title: '작성일',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
        },
        {
          field: 'viewCnt',
          title: '조회수',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
        },
        {
          field: 'sendYn',
          title: '안내발송여부',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: { ...centerAttributes, ...rowRowAttributes },
        },
      ],
    }
  },
}
</script>
<style scoped>
.notice-top-row {
  background: #fff5e6!important;
}
</style>
